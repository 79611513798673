<template>
    <div class="bg-white p-4 my-3 shadow rounded">
        <form novalidate="novalidate">
            <div class="d-flex justify-content-between mb-3">
                <button class="btn btn-block mt-0 mx-2 btn-lg" type="button" :class="{'btn-primary': type === 'hotels', 'btn-outline-primary': type !== 'hotels'}" @click="type = 'hotels'">
                    Hoteles
                </button>
                <button class="btn btn-block mt-0 mx-2 btn-lg" type="button" :class="{'btn-primary': type === 'tours', 'btn-outline-primary': type !== 'tours'}" @click="type = 'tours'">
                    Tours
                </button>
                <button class="btn btn-block mt-0 mx-2 btn-lg" type="button" :class="{'btn-primary': type === 'transfers', 'btn-outline-primary': type !== 'transfers'}" @click="type = 'transfers'">
                    Traslados
                </button>
            </div>
            <div class="mb-4">
                <h2 class="h4">{{ __('Book at the best hotels at the best price.') }}</h2>
            </div>
            <div class="mb-3">
                <label class="">
                    <svg class="bi bi-geo text-muted" width="1rem" height="1rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0z"/>
                        <path d="M9.5 6h1v9a.5.5 0 01-1 0V6z"/>
                        <path fill-rule="evenodd" d="M8.489 14.095a.5.5 0 01-.383.594c-.565.123-1.003.292-1.286.472-.302.192-.32.321-.32.339 0 .013.005.085.146.21.14.124.372.26.701.383.655.245 1.593.407 2.653.407s1.998-.162 2.653-.407c.329-.124.56-.259.701-.383.14-.125.146-.197.146-.21 0-.018-.018-.147-.32-.339-.283-.18-.721-.35-1.286-.472a.5.5 0 11.212-.977c.63.137 1.193.34 1.61.606.4.253.784.645.784 1.182 0 .402-.219.724-.483.958-.264.235-.618.423-1.013.57-.793.298-1.855.472-3.004.472s-2.21-.174-3.004-.471c-.395-.148-.749-.337-1.013-.571-.264-.234-.483-.556-.483-.958 0-.537.384-.929.783-1.182.418-.266.98-.47 1.611-.606a.5.5 0 01.595.383z" clip-rule="evenodd"/>
                    </svg>
                    {{ __('Where') }}
                </label>
                <div class="input-group input-group form">
                    <p class="vbst-item list-group-item list-group-item-action" v-if="loading">{{ __('Searching') }}</p>
                                            <!-- minMatchingChars="3" -->

                    <vue-bootstrap-typeahead
                        style="width:100%"
                        class="mb-4"
                        :loading="loading"
                        :data="cities" 
                        v-model="citySearch" 
                        backgroundVariant="bg-primary"
                        :minMatchingChars="3"
                        size="lg"
                        :serializer="s => s.city_name" 
                        :placeholder="__('Search') + '...'" 
                        @hit="selectedCity = $event"
                        inputClass="border-primary"
                        >
                        
                        <template slot="prepend">
                            <span class="input-group-text bg-primary border-primary text-white">
                                <svg class="bi bi-map" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M17.817 2.613A.5.5 0 0118 3v13a.5.5 0 01-.402.49l-5 1a.502.502 0 01-.196 0L7.5 16.51l-4.902.98A.5.5 0 012 17V4a.5.5 0 01.402-.49l5-1a.5.5 0 01.196 0l4.902.98 4.902-.98a.5.5 0 01.415.103zM12 4.41l-4-.8v11.98l4 .8V4.41zm1 11.98l4-.8V3.61l-4 .8v11.98zm-6-.8V3.61l-4 .8v11.98l4-.8z" clip-rule="evenodd"/>
                                </svg>
                            </span>
                        </template>

                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <div class="d-flex align-items-center">
                                <i style="position: absolute;font-size: 22px;left: 10px;color: #E91E63;" class="fa fa-map-marker"></i>
                                <!-- Note: the v-html binding is used, as htmlText contains
                                    the suggestion text highlighted with <strong> tags -->
                                <span class="ml-4" v-html="htmlText"></span>
                                <!-- <i class="ml-auto fab fa-github-square fa-2x"></i>  -->
                            </div>
                        </template>

                    </vue-bootstrap-typeahead>
                    <small v-if="errors.length">
                        {{ __('Select a destination') }}    
                    </small>
                        <!-- <input type="text" class="form-control form__input typeahead" name="city_name" required=""
                        placeholder="Destino" aria-label="Ingresa tu destino"> -->
                </div>
            </div>
            <div class="mb-3">
                <label class="">
                    <svg class="bi bi-calendar-fill text-muted" width="1rem" height="1rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                        <path d="M2 4a2 2 0 012-2h12a2 2 0 012 2H2z"/>
                        <path fill-rule="evenodd" d="M2 5h16v11a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm6.5 4a1 1 0 100-2 1 1 0 000 2zm4-1a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2zm-8 2a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2zm4-1a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2zm-8 2a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2zm4-1a1 1 0 11-2 0 1 1 0 012 0z" clip-rule="evenodd"/>
                    </svg>
                    {{ __('Date') }}
                </label>
                <select class="form-control form-control-lg border-primary" v-model="selectedMonth">
                    <option v-for="month in months" :value="month.month" :key="month.month">{{ month.name }} {{ month.year }}</option>
                </select>
            </div>

            <div class="dropdown">
                <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ currency }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="/currency-code?currency_page=USD">
                        USD
                    </a>

                    <a class="dropdown-item" href="/currency-code?currency_page=MXN">
                        MXN
                    </a>
                </div>
            </div>

            <button type="button" class="btn btn-block btn-lg btn-primary" v-on:click="checkForm" >
                {{ __('Search for the best offer') }}
            </button>
        </form>
       <!-- <loader-component v-if="loader"></loader-component>  -->
    </div>
</template>
<script>
    import debounce from 'lodash/debounce';

    const API_URL = '/api/cities/:query'
    export default {
        props: ['currency', 'months'],
        data() {
            return {
                type: 'hotels',
                cities: [],
                citySearch: '',
                selectedCity: null,
                selectedMonth: 1,
                selectedCurrency: 'USD',
                currentYear : '2019',
                errors: [],
                loader: false,
                 loading: false
            }
        },
        mounted() {
            var currentDate = new Date();
            var currentMonth = currentDate.getMonth() + 1
            this.currentYear = currentDate.getFullYear() + 1
            this.selectedMonth = currentMonth
            if(this.selectedMonth == 12) this.selectedMonth = 1
            else this.selectedMonth = this.selectedMonth + 2
        },
        methods: {
            async getCities(query) {
                this.loading = true
                const res = await fetch(API_URL.replace(':query', query))
                const suggestions = await res.json()
                this.cities = suggestions
                this.loading = false
            },
            checkForm: function (e) {
                
                if (this.selectedCity && this.selectedMonth) {
                    $('#cover-spin').show(0)
                    this.loader = true;
                    localStorage.setItem('searchParams', 
                    JSON.stringify({
                        destination: this.selectedCity,
                        month: this.selectedMonth,
                        currency: this.selectedCurrency
                    }))
                    window.location.href = '/list/hotels';
                }

                this.errors = [];

                if (!this.selectedCity) {
                    this.errors.push(this.__('Name is mandatory.'));
                }

                e.preventDefault();
            },
        },
        watch: {
            citySearch: debounce(function (city) {
                if(this.selectedCity === null)
                this.getCities(city)
                if(this.selectedCity !== null && this.selectedCity.city_name != city)
                this.getCities(city)
            }, 200)
        }
    }

</script>
