require('./bootstrap');

window.Vue = require('vue');

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VuePaginate from 'vue-paginate'
import VueRecaptcha from 'vue-recaptcha'
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VuePaginate)

let token = document.head.querySelector('meta[name="csrf-token"]').content;

axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

Vue.directive('swiper', {
    inserted: function(el, binding) {

        let xs = 1;
        let md = 2;
        let lg = 3;
        let xl = 4;

        if (typeof binding.value === 'object') {
            xs = binding.value.xs || xs;
            md = binding.value.md || md;
            lg = binding.value.lg || lg;
            xl = binding.value.xl || xl;
        }

        new Swiper(el, {
            slidesPerView: 2,
            spaceBetween: 10,
            direction: 'vertical',
            loop: true,  
            roundLengths: true,
            // slidesOffsetBefore: 0,
            // slidesPerGroup: 2,
            breakpoints: {
                1200: {
                    slidesPerView: 2,
                    direction: 'vertical'
                },
                992: {
                    slidesPerView: lg,
                    direction: 'horizontal',
                },
                768: {
                    slidesPerView: xs,
                    direction: 'horizontal',
                },
                0: {
                    slidesPerView: xs,
                    direction: 'horizontal',
                }
            },
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
})

Vue.component('csrf-token', {
    functional: true,
    render: function(h) {
        return h('input', {
            attrs: {
                type: 'hidden',
                name: '_token',
                value: token
            }
        });
    }
});

Vue.use(VueGoogleMaps, {
    load: {
        region: 'US',
        language: 'es',
        key: 'AIzaSyDpkGTylz16XwkHfGuZkIX3WpO6vPfvaBQ',
        libraries: 'places',
    },
})

Vue.mixin(require('./trans.js').default);
Vue.component('googlemap', require('./components/AddGoogleMap.vue').default);
Vue.component('pendient-payment-membership', require('./components/PendientPaymentMembership.vue').default);
Vue.component('client-information-yate-component', require('./components/ClientInformationYate.vue').default);
Vue.component('modal', require('./components/Modal.vue').default);
Vue.component('card-component', require('./components/Card.js').default);
Vue.component('stripe-checkout', require('./components/StripeCheckout.vue').default);
Vue.component('stripe-checkout-new', require('./components/StripeCheckoutNew.vue').default);
Vue.component('register-component', require('./components/Register.vue').default);
Vue.component('register-client', require('./components/Client.vue').default);
Vue.component('leaf-message', require('./components/LeafMessage.vue').default);

Vue.component('search-bar-component', require('./components/SearchBar.vue').default);
Vue.component('horizontal-yates-search-bar-component', require('./components/HorizontalSearchBarYate.vue').default);
Vue.component('horizontal-search-bar-component', require('./components/HorizontalSearchBar.vue').default);
Vue.component('horizontal-search-bar-component-step', require('./components/HorizontalSearchBarStep.vue').default);
Vue.component('horizontal-tour-search-bar-component', require('./components/HorizontalTourSearchBar.vue').default);
Vue.component('horizontal-car-rental-search-bar-component', require('./components/HorizontalCarRentalSearchBar.vue').default);

Vue.component('loader-component', require('./components/Loader.vue').default);
Vue.component('hotel-list-component', require('./components/HotelList.vue').default);
Vue.component('details-hotel-component', require('./components/DetailsHotel.vue').default);
Vue.component('details-yate-component', require('./components/DetailsYate.vue').default);
Vue.component('details-condo-component', require('./components/DetailsCondo.vue').default);
Vue.component('client-information-component', require('./components/ClientInformation.vue').default);
Vue.component('client-information-component-add', require('./components/ClientInformationAdd.vue').default);
Vue.component('client-information-condo-component', require('./components/ClientInformationCondo.vue').default);
Vue.component('checkout-component', require('./components/Checkout.vue').default);
Vue.component('pendient-payment-cupon-component', require('./components/PendientPaymentCupon').default);
Vue.component('confirmation-component', require('./components/Confirmation.vue').default);
// Vue.component('additionals-information-component', require('./components/AdditionalsInformation').default);

Vue.component('book-tour-form', require('./components/BookTourForm.vue').default);
Vue.component('book-car-rental-form', require('./components/BookCarRentalForm.vue').default);

Vue.component('coupons', require('./components/Coupons.vue').default);
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)

Vue.component('vue-recaptcha', VueRecaptcha)

Vue.component('add-tour-to-cart', require('./components/AddTourToCart.vue').default);
Vue.component('cart-item-list', require('./components/CartItemList.vue').default);
Vue.component('cart-checkout', require('./components/CartCheckout.vue').default);
Vue.component('membership-list', require('./components/MembershipList.vue').default);
Vue.component('pay-membership-form', require('./components/PayMembershipForm.vue').default);
Vue.component('membership-select-upgrade', require('./components/MembershipSelectUpgrade.vue').default);
Vue.component('pending-payment-form', require('./components/PendingPaymentForm.vue').default);


import VueCurrencyFilter from 'vue-currency-filter';

Vue.use(VueCurrencyFilter, {
  symbol : '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
});

import VueSweetalert2 from 'vue-sweetalert2';
 
Vue.use(VueSweetalert2);

import format from "date-fns/format";
import parse from "date-fns/parse";

Vue.filter('formatDate', function(value) {
    if (value) {
        return format(parse(value, 'yyyy-MM-dd', new Date()), 'dd/MMMM/yyyy')
    }
})

const app = new Vue({
    el: '#app',
});
