<template>
  <form id="scroll" @submit.prevent="submit">
    <div v-if="errorPayment" class="alert alert-danger text-sm mb-3">
      <div class="media align-items-center">
          <p class="subtitle text-danger" v-if="errorPayment">{{ __('Payment unsuccessful please try again') }}</p>
      </div>
    </div>
    <div class="p-3 mt-3 rounded" style="background: #EDF2F7;">
        <h5 style="color: #482448;"> {{ __('Payment method') }}</h5><br>
        <div role="alert" style="background: #ff000024;">
          {{ errorMessage}}
        </div>
        <div class="row">
          <div class="col-8">
            <span class="text-success font-weight-bold">
              <i class="fa fa-shield-alt"></i> {{ __('Secure payment')}}
            </span>
          </div>
          <div class="col">
            <img src="/images/cards/visa.png"/>
            <img src="/images/cards/mastercard.png"/>
          </div>
        </div>
    <p style="color: #482448;"><span style="font-weight: 700">$ {{ ttl }}  {{this.currency}} / </span><span> {{ __('Membership Payment')}}</span></p>
      <div class="row">
        <div class="col-md-6">
          <label class="text-dark font-weight-bold">
            <svg xmlns="https://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <circle cx="12" cy="7" r="4" />
              <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
            </svg>
            {{ __('Cardholder') }}
          </label>
          <input class="form-control mb-3" v-model="cardholder" placeholder="John Doe" />
        </div>
        <div class="col-md-6">
          <label class="text-dark font-weight-bold">
            <svg xmlns="https://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-credit-card" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <rect x="3" y="5" width="18" height="14" rx="3" />
              <line x1="3" y1="10" x2="21" y2="10" />
              <line x1="7" y1="15" x2="7.01" y2="15" />
              <line x1="11" y1="15" x2="13" y2="15" />
            </svg>
            {{ __('Card number') }}
          </label>
          <div id="card-element" ref="card-element">
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="text-dark font-weight-bold">
            <svg xmlns="https://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <rect x="4" y="5" width="16" height="16" rx="2" />
              <line x1="16" y1="3" x2="16" y2="7" />
              <line x1="8" y1="3" x2="8" y2="7" />
              <line x1="4" y1="11" x2="20" y2="11" />
              <line x1="11" y1="15" x2="12" y2="15" />
              <line x1="12" y1="15" x2="12" y2="18" />
            </svg>  
            {{ __('Month') }} / {{ __('Year') }}
          </label>
          <div id="card-expiry" ref="card-expiry"></div>
        </div>
        <div class="col-md-6">
          <label class="text-dark font-weight-bold">
            <svg xmlns="https://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-key" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <circle cx="8" cy="15" r="4" />
              <line x1="10.85" y1="12.15" x2="19" y2="4" />
              <line x1="18" y1="5" x2="20" y2="7" />
              <line x1="15" y1="8" x2="17" y2="10" />
            </svg>  
            CVC
          </label>
          <div id="card-cvc" ref="card-cvc"></div>
        </div>
      </div><br>

      <div class="row">
        <div class="col-7"></div>
        <div class="col">
          <button id="submit" class="btn btn-primary btn-block mt-3" :disabled="charging || this.errorMessage || disabled || !cardholder">
            <i class="fa fa-spin fa-spinner" v-if="charging"></i> {{ type === 'payment' ? __('Pay') : __('Register') }}
            $ {{ ttl }}  {{this.currency}}    
          </button>
        </div>
      </div><br><br>
      <div class="row">
        <div class="col-7"></div>
        <div class="col">
              <img src="/images/cards/stripe.png" style="width: 100px;" />
              <img src="/images/cards/aws.png" style="width: 100px; margin-left: -5px;" />
              <img src="/images/cards/amazon.png" style="width: 50px;" />
        </div>
      </div>

      </div>
    </div>
  </form>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import round from 'lodash/round'

export default {
  props: {
    total: {
      default: 0
    },
    currency: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'payment' // or createMethod
    },
    disabled: {
      type: Boolean,
      default: false
    },
    subscribible: {
      type: Boolean,
      default: false
    },
    showTerms: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: 'es'
    }
  },
  data() {
    return {
      cardholder: '',
      clientSecret: null,
      charging: false,
      errorPayment:false,
      errorMessage: null,
      error: null,
      terms_and_conditions: false,
      style: {
        base: {
          color: "#5A5A5A",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        }
      }
    }
  },
  async mounted() {
    this.createIntent(round(parseFloat(this.total), 2));
    // Stripe instance doesn't need reactive
    this.stripe = await loadStripe('pk_live_z3SHe0e9sgwq7tjvZActsGrE002ih7XSgd');
    const elements = this.stripe.elements();
    this.card = elements.create('cardNumber', { style: this.style });
    this.card.mount(this.$refs['card-element']);
    this.cardExpiry = elements.create('cardExpiry', { style: this.style });
    this.cardExpiry.mount(this.$refs['card-expiry']);
    this.cardCvc = elements.create('cardCvc', { style: this.style });
    this.cardCvc.mount(this.$refs['card-cvc']);
    this.card.on('change', ({error}) => {
      if (error) {
        this.errorMessage = error.message;
      } else {
        this.errorMessage = null;
      }
    });
  },
  computed: {
    hasClientSecret() {
      return this.clientSecret !== null;
    },
    ttl() {
      return round(parseFloat(this.total), 2);
    }
  },
  created() {
    // window.addEventListener('beforeunload', this.cancelCurrentIntent);
  },
  methods: {
    async submit() {
      this.errorPayment = false;
      this.charging = true;
      this.$emit('requesting-payment');
      let response;
      if (this.type === 'payment') {
        let d = {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.cardholder
            }
          },
        };
        
        if (this.subscribible) {
          d.setup_future_usage = 'off_session';
        }
      
        response = await this.stripe.confirmCardPayment(this.clientSecret.client_secret, d);
      
      } else {
        response = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card
        });
      }

      if (response.error) {
        this.$emit('payment-failure', response.error);
        this.errorPayment = true;
        this.error = response.error;
         this.charging = false;
        return;
      } else {
        if (this.type === 'payment' && response.paymentIntent.status === 'succeeded') {
          this.errorPayment = false;
          this.$emit('payment-successfully', this.clientSecret);
        }else{
          this.$emit('create-payment-method-successfully', response.paymentMethod.id)
        }
      }
    },
    async createIntent(total) { 
      
      if (isNaN(total) || total <= 0 ) {
        return;
      }

      const body = {
        amount: total,
        currency: this.currency
      };
      if (this.hasClientSecret) {
        const { data } = await this.updateCurrentIntent(body);
        this.clientSecret = data;
        return;
      }
      if (this.subscribible) {
        body.create_customer = 1;
      }
      const { data } = await axios.post('/payment-intent', body);
      this.clientSecret = data;
    },
    async cancelCurrentIntent() {
      if (this.clientSecret !== null) {
        return axios.delete(`/payment-intent/${this.clientSecret.id}`);
      }
      return null;
    },
    async updateCurrentIntent(body) {
      if (this.clientSecret !== null) {
        return axios.put(`/payment-intent/${this.clientSecret.id}`, body);
      }
      return null;
    }
  },
  destroyed() {
    // window.removeEventListener('beforeunload', this.cancelCurrentIntent);
  },
  watch: {
    total: {
      handler: function (newTotal, oldTotal) {
        if (newTotal !== oldTotal) {
          this.createIntent(round(parseFloat(newTotal), 2));
        }
      }
    }
  }
}
</script>

<style scoped>
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #DDD;
  border-radius: 4px;
  background-color: white;
  /* box-shadow: 0 1px 3px 0 #e6ebf1; */
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>