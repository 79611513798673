<template>
    <div>
        <form class="px-4 py-3" novalidate="novalidate"> 
            <div class="form-group">
                <label for="exampleDropdownFormEmail1" ><strong>{{ __('Enter Travel Voucher') }}</strong></label>
                <input type="text" class="form-control" id="CodeCoupon" :placeholder="__('Example: XGDR322')" v-model="coupon_code">
            </div>
            <div class="alert alert-success" v-if="isFinish">  
                {{ __('Travel Voucher saved successfully') }}
            </div>
            <div class="alert alert-danger" v-if="errors.length > 0">   
                <div v-for="error in errors" :key="error">{{ error }}</div>
            </div>
            <button type="button" class="btn btn-danger" v-on:click="CancelSave">{{ __('Cancel') }}</button>
            <button type="button" 
                class="btn btn-success" 
                v-on:click="verify"
                :disabled="disableButton"
                >
                <i class="fas fa-spinner fa-spin mr-2" v-if="disableButton" ></i>
                {{ __('Save') }}
            </button>

            <div class="mt-3">
                <div class="dropdown-divider"></div>
                <button type="button" class="btn" style="color: #0e81c3" @click="showModal=true">{{ __('See my travel vouchers') }} </button>
            </div>
            
        </form>

        
        <modal v-if="showModal" @close="showModal = false">
            <template slot="modal-header">
                <h4>{{ __('My Travel Vouchers') }}</h4>
            </template>
            <template slot="modal-body">
                <table class="table table-striped m-0">
                    <thead>
                        <tr>
                        <th scope="col">{{ __('Travel voucher') }}</th>
                        <th scope="col">{{ __('Value') }}</th>
                        <th scope="col">{{ __('Deadline') }}</th>
                        <th scope="col">{{ __('used?') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in Coupons" :key="item.id">
                            <td>{{ item.Coupon_Code }}</td>
                            <td>{{ item.currency_code }} {{ item.value }}</td>
                            <td>{{ item.valid_until }}</td>
                            <td>
                                <div v-if="item.isUsed === 1">{{ __('Yes') }}</div>
                                <div v-else>No</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template >
        </modal>

       <!-- <b-modal ref="modal-coupon" size="lg">
            <template v-slot:modal-title>
                <slot name="modal-header" :item="itemModal"></slot>
            </template>
            
            <slot name="modal-body">

            </slot>

            <template v-slot:modal-footer>
                <slot name="modal-footer" :item="itemModal" :closeModal="closeModal"></slot>
            </template>
        </b-modal> -->
    </div>
    
</template>
<script>
    export default {
        data() {
            return {
                coupon_code: '',
                isused: false,
                isFinish: false,
                errors: [],
                Coupons: [],
                showModal: false,
                itemModal: {},
                disableButton: false,
                coupon_id: 0
            }
        },
        mounted() {
            this.loadCoupons();
        },
        computed:{
            // disableButton() {
            //     return (
            //         !this.coupon_code 
            //     );
            // }
        },
        methods: {
            loadCoupons(){
                var vm =this;
                axios.get('/coupons/list')
                .then(function (response) {
                    
                    if (response.status == 200) {
                        vm.Coupons = response.data.coupons;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            ShowCoupons() {
                this.itemModal = item;
                this.$refs['modal-coupon'].show();
            },
            closeModal() {
              this.$refs['modal-coupon'].hide();
            },
            CancelSave(){
                this.coupon_code = '';
                this.errors = [];
                this.isFinish = false;
            }
            ,verify: function(){
                if(this.coupon_code == '') return false;
                var vm = this;
                vm.errors = [];
                vm.isFinish = false;
                vm.disableButton = true;

                axios.get('/coupon/'+this.coupon_code)
                .then(function (response) {

                    if (response.status == 200) {
                        if (response.data.is_valid) {
                            vm.coupon_id = response.data.coupon.id;
                            vm.save();
                        }else{
                            vm.errors.push(response.data.message)
                            vm.isFinish=false
                            vm.disableButton = false;
                        }   
                    }
                    
                })
                .catch(function (error) {
                    console.log(error);
                    vm.isFinish=false
                    vm.disableButton = false;
                });
            },
            save: function(){
                var vm = this;
                vm.errors = [];
                vm.isFinish = false;
                
                axios.post('/coupons', {
                    coupon_code: this.coupon_code,
                    client_id: this.client_id,
                    coupon_id: this.coupon_id,
                    isused: 0
                })
                .then(function (response) {
                    
                    if (response.status == 200) {
                        if (response.data.is_valid) {
                            vm.isFinish = true;
                            vm.errors = [];
                            vm.coupon_code = '';
                            vm.disableButton = false;

                            vm.loadCoupons();
                            setTimeout(() => vm.isFinish=false, 5000);

                        }else{
                            vm.disableButton = false;
                            vm.errors.push(response.data.message);
                        }
                    }
                })
                .catch(function (error) {
                    vm.isFinish=false
                    vm.disableButton = false;
                    console.log(error);
                });
            }
        }
    }

</script>