<template>
  <div class="w-100 py-5 px-md-5 px-xl-6 position-relative">

    <div class="mb-4">
      <h2 class="font-weight-bold" style="color: #0078b3 !important;">Vacancy Rewards</h2>
      <p class="text-muted" v-if="membershipTypeSelected !== null">{{ __('Sign up and start getting great benefits') }}</p>
      <p v-else>{{ __('Redeem your travelvoucher and start enjoying great benefits') }}</p>
    </div>

    <img src="/images/voucher.jpeg" alt="coupon register" class="img-fluid" v-if="membershipTypeSelected === null">

    <form
      class="form-validate mt-3 pl-3 pr-3 border-right border-left"
      id="register-form"
      action="/subscriptions"
      method="POST"
      ref="subscriptionForm"
    >
      <csrf-token></csrf-token>
      <input type="hidden" name="payment_method_id" :value="payment_method_id">

      <div class="row">
        <div class="form-group col-md-6">
          <label for="name" class="form-label">{{ __('Name') }}</label>
          <input
            name="name"
            id="name"
            type="string"
            placeholder="John"
            autocomplete="off"
            required
            data-msg="Please enter your name"
            class="form-control"
            :class="{ 'is-invalid': hasErrors('name') }"
            v-model="client.name"
          />
          <div class="invalid-feedback" v-for="(error, index) in getErrors('name')" :key="index">
            {{ error }}
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="last_name" class="form-label">{{ __('Last Name') }}</label>
          <input
            name="last_name"
            id="last_name"
            type="string"
            placeholder="Doe"
            autocomplete="off"
            required
            data-msg="Please enter your last name"
            class="form-control"
            :class="{ 'is-invalid': hasErrors('last_name') }"
            v-model="client.last_name"
          />
          <div class="invalid-feedback" v-for="(error, index) in getErrors('last_name')" :key="index">
            {{ error }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="email" class="form-label">{{ __('Email') }}</label>
        <input
          name="email"
          id="email"
          type="email"
          placeholder="name@address.com"
          autocomplete="off"
          required
          data-msg="Please enter your email"
          class="form-control"
          :class="{ 'is-invalid': hasErrors('email') }"
          v-model="client.email"
        />
        <div class="invalid-feedback" v-for="(error, index) in getErrors('email')" :key="index">
          {{ error }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="international_code" class="form-label">{{ __('Country') }}</label>
            <select
              name="international_code"
              id="international_code"
              required
              class="form-control"
              :class="{ 'is-invalid': hasErrors('international_code') }"
              v-model="client.international_code"
            >
              <option :value="null">{{ __('Choose a option') }}</option>
              <option :value="'+52'">MEXICO +52</option>
              <option :value="'+1'">UNITED STATES +1</option>
              <option :value="'+1'">CANADA +1</option>
              <option :value="'+57'">COLOMBIA +57</option>
              <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
            </select>
            <div class="invalid-feedback" v-for="(error, index) in getErrors('international_code')" :key="index">
              {{ error }}
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="form-group">
            <label for="phone_number" class="form-label">{{ __('Phone number') }}</label>
            <input
              name="phone_number"
              id="phone_number"
              type="phone_number"
              placeholder="999 555 6226"
              autocomplete="off"
              required
              data-msg="Please enter your phone number"
              class="form-control"
              :class="{ 'is-invalid': hasErrors('phone_number') }"
              v-model="client.phone_number"
            />
            <div class="invalid-feedback" v-for="(error, index) in getErrors('phone_number')" :key="index">
              {{ error }}
            </div>
          </div>
        </div>
      </div>

      <!--div class="row">
        <div class="col-md-9">
          <div class="js-form-message mb-3">
            <label>{{ __('Address') }}</label>
            <div class="js-focus-state input-group input-group form">
              <input
                type="text"
                class="form-control form__input"
                :class="{ 'is-invalid': hasErrors('address') }"
                name="address"
                required
                :placeholder="__('Address')"
                aria-label="Address"
                v-model="client.address"
              />
              <div class="invalid-feedback" v-for="(error, index) in getErrors('address')" :key="index">
                {{ error }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="js-form-message mb-3">
            <label>{{ __('Zip code') }}</label>
            <div class="js-focus-state input-group input-group form">
              <input
                type="text"
                class="form-control form__input"
                :class="{ 'is-invalid': hasErrors('zip_code') }"
                name="zip_code"
                required
                placeholder="77711"
                aria-label="Código postal"
                v-model="client.zip_code"
              />
              <div class="invalid-feedback" v-for="(error, index) in getErrors('zip_code')" :key="index">
                {{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="js-form-message mb-3">
            <label>{{ __('Country') }}</label>
              <div class="js-focus-state input-group input-group form">
                  <select
                      class="form-control form__input" 
                      name="country"
                      required="" 
                      aria-label="Country"
                      v-model="client.country"
                  >
                      <option :value="null">{{ __('Choose a option') }}</option>
                      <option :value="121">MEXICO</option>
                      <option :value="195">USA</option>
                      <option :value="32">CANADA</option>
                      <option :value="40">COLOMBIA</option>
                      <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </option>
                      
                  </select>
              </div>
          </div>
        </div>
         <div class="col-md-4" v-if="stateOptions.length > 0">
          <div class="js-form-message mb-3">
            <label>{{ __('State') }}</label>
            <div class="js-focus-state input-group input-group form">
              <select 
                class="form-control form__input" 
                :class="{ 'is-invalid': hasErrors('state_id') }"
                name="state_id"
                placeholder="Estado" 
                aria-label="estado"
                v-model="client.state"
              >
                <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
              <div class="invalid-feedback" v-for="(error, index) in getErrors('state_id')" :key="index">
                {{ error }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="js-form-message mb-3">
            <label>{{ __('City') }}</label>
            <div class="js-focus-state input-group input-group form">
              <input 
                class="form-control form__input" 
                :class="{ 'is-invalid': hasErrors('city_id') }"
                name="city_id"
                required="" 
                placeholder="Ciudad" 
                aria-label="Ciudad"
                v-model="client.city"
              >
              <div class="invalid-feedback" v-for="(error, index) in getErrors('city_id')" :key="index">
                {{ error }}
              </div>
            </div>
          </div>
        </div>
      </div-->

      <div class="form-group">
        <label for="password" class="form-label">{{ __('Password') }}</label>
        <input
          name="password"
          id="password"
          placeholder="Password"
          type="password"
          required
          data-msg="Please enter your password"
          :class="{ 'is-invalid': hasErrors('password') }"
          class="form-control"
          v-model="client.passport"
        />
        <div class="invalid-feedback" v-for="(error, index) in getErrors('password')" :key="index">
          {{ error }}
        </div>
      </div>
      <div class="form-group mb-4">
        <label for="password_confirm" class="form-label">{{ __('Confirm password') }}</label>
        <input
          name="password_confirmation"
          id="password_confirm"
          placeholder="Password"
          type="password"
          required
          data-msg="Please enter your password"
          class="form-control"
          :class="{ 'is-invalid': hasErrors('password_confirmation') }"
          v-model="client.password_confirmation"
        />
        <div class="invalid-feedback" v-for="(error, index) in getErrors('password_confirmation')" :key="index">
          {{ error }}
        </div>
      </div>
      <input type="hidden" name="membership_type_id" :value="client.membership_type"/>
      <div class="form-group" v-if="membershipTypeSelected !== null">
        <div class="form-label">{{ __('Membership') }}</div>
        <select
          id="membership_id" 
          class="form-control" 
          :class="{ 'is-invalid': hasErrors('membership_type_id') }"
          v-model="client.membership_type"
        >
          <option v-if="lang == 'es'" v-for="membershipType in membershipTypes"
            :key="membershipType.id"
            :value="membershipType.id"
          >
            {{ membershipType.name }} 
          </option>
          <option v-if="lang == 'en'" v-for="membershipType in membershipTypes"
            :key="membershipType.id"
            :value="membershipType.id"
          >
            {{ membershipType.name_en }} 
          </option>

        </select>
        <div class="invalid-feedback" v-for="(error, index) in getErrors('membership_type_id')" :key="index">
          {{ error }}
        </div>
      </div>

      <div v-if="membershipTypeSelected && membershipType">
        {{ __('Monthly cost')}}: 
        <span class="font-weight-bold">
          $ {{ currency.code === 'MXN' ? membershipType.price_string : membershipType.usd_price_string }}
        </span>
      </div>

      <div class="mb-4 mt-4" v-if="client.membership_type !== null && client.membership_type > 0">
        <h5 class="mb-0">{{ __('Participating Bank Cards') }}</h5>
        <div class="text-muted mt-3">
          <img src="/images/visa-logo.png" alt="visa">
          <img src="/images/mastercard-logo.png" alt="mastercard" class="d-inline-block ml-3">
        </div>
      </div>

      <div class="form-group">
          <label for="coupon" class="form-label">{{ client.membership_type == 0 ? __('Travel Voucher') : __('I have a travel voucher for 1 free month') }}</label>
          
          <div class="input-group">
            <input
              type="text"
              name="coupon"
              :placeholder="__('Travel Voucher')"
              id="coupon"
              class="form-control"
              v-model="coupon"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-primary" type="button" @click="validateCoupon" :disabled="is_coupon_loading">{{ __('Validate') }}</button>
            </div>
          </div>

          <div v-if="coupon_message">{{ coupon_message }}</div>
      </div>

      <div v-if="client.membership_type !== null && client.membership_type > 0">
        <card-component
          #default="{holderName, cardNumber, cvv2, expirationYear, expirationMonth, states, handlers, validators, monthOptions, yearOptions}"
          :years="years"
          :now="now"
          v-if="currency.code === 'MXN'"
        >
          <div class="row" id="addNewCard">
            <div class="form-group col-md-6">
              <label for="holder_name" class="form-label">{{ __('Cardholder') }}</label>
              <input
                type="text"
                name="holder_name"
                :placeholder="__('Cardholder')"
                id="holder_name"
                class="form-control"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="card_number" class="form-label">{{ __('Card number') }}</label>
              <input
                type="text"
                name="card_number"
                :placeholder="__('Card number')"
                id="card_number"
                class="form-control"
                @input="handlers.cardNumber"
                @blur="validators.cardNumber"
                :value="cardNumber"
                :class="{'is-invalid': !states.cardNumber || hasErrors('card_number')}"
              />
              <div v-if="!states.cardNumber || hasErrors('card_number')" class="text-danger">{{ __('Invalid card number') }}</div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="expiration_month" class="form-label">{{ __('Month') }}</label>
                  <select
                    :value="expirationMonth"
                    @change="handlers.expirationMonth"
                    @blur="validators.expirationDate"
                    name="expiration_month"
                    class="form-control"
                    :class="{'is-invalid': !states.expirationDate || hasErrors('expiration_month')}"
                  >
                    <option
                      v-for="option in monthOptions"
                      :key="option.value"
                      v-bind:value="option.value"
                    >{{ option.text }}</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="expiration_year" class="form-label">{{ __('Year') }}</label>
                  <select
                    :value="expirationYear"
                    @change="handlers.expirationYear"
                    @blur="validators.expirationDate"
                    name="expiration_year"
                    class="form-control"
                    :class="{'is-invalid': !states.expirationDate || hasErrors('expiration_year')}"
                  >
                    <option
                      v-for="option in yearOptions"
                      :key="option.value"
                      v-bind:value="option.value"
                    >{{ option.text }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="cvv2" class="form-label">CVC/CVV</label>
              <input
                type="text"
                name="cvv2"
                placeholder="123"
                id="cvv2"
                class="form-control"
                @input="handlers.cvv2"
                @blur="validators.cvv2"
                :value="cvv2"
                :class="{'is-invalid': !states.cvv2 || hasErrors('cvv2')}"
              />
              <div v-if="!states.cvv2 || hasErrors('cvv2')" class="text-danger">CVV {{ __('Incorrect') }}</div>
            </div>
          </div>
        </card-component>
        <stripe-checkout v-else
          type="createMethod"
          :currency="currency.code"
          @create-payment-method-successfully="createPaymentMethodSuccessfully"
          :disabled="disableButton"
        >
        </stripe-checkout>
      </div>

      <!--div class="form-group mb-4">
        <template>
          <vue-recaptcha 
          sitekey="6LfpS6gZAAAAABDvDo7FeMwPzeUjlQgVmcaFPFJc" 
          :loadRecaptchaScript="true"
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          >
          </vue-recaptcha>
        </template>
      </div-->
      
      <hr class="my-4" />
      <div class="form-group col-md-12">
      <center>
      <p class="text-sm text-muted">
        <input type="checkbox" class="form-group form-check-input" v-model="terms_and_conditions" id="terms_and_conditions">
        {{ __('With the user registration you accept') }}
        <a :href="`/termsAndConditions`" target="_blank" style="color: #0078b3 !important;">
          {{ __('Terms and conditions') }}
        </a> {{ __('and') }}
        <a :href="`/termsAndConditions`" target="_blank" style="color: #0078b3 !important;">{{ __('Privacy policies') }}</a>.
      </p>
      </center>
      </div>
      <hr class="my-4" />
       <button
        type="submit"
        style="background-color: #0078b3 !important; color: #fff;"
        class="btn btn-lg btn-block"
        :disabled="disableButton">
        {{ __('Register') }}
      </button>
    </form>
    <a href="index.html" class="close-absolute mr-md-5 mr-xl-6 pt-5 d-none">
      <svg class="svg-icon w-3rem h-3rem">
        <use xlink:href="#close-1" />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  props: ['old', 'errors', 'membershipTypes', 'membershipTypeSelected', 'years', 'now', 'lang', 'codes', 'currency'],
  data() {
    return {
      client: {  
        name: '',
        last_name: '',
        email: '',
        phone_number: '',
        address: '',
        zip_code: '',
        passport: '',
        password_confirmation: '',
        membership_type: null,
        country: null,
        city: null,
        state: null,
        international_code: null
      },
      coupon: '',
      coupon_validated: false,
      valid_coupon: false,
      is_coupon_loading: false,
      coupon_message: null,
      terms_and_conditions: false,
      captchaver: true,
      countries: [],
      payment_method_id: null,
    }
  },
  mounted() {
    this.loadCountries();

    if (this.membershipTypeSelected) {
      this.client.membership_type = this.membershipTypeSelected;
    } else {
      this.client.membership_type = 0;
    }

    this.checkOld();
  },
  computed: {
    disableButton() {
      return !this.terms_and_conditions 
      || (!this.valid_coupon && this.coupon !== '')
      || !this.captchaver;
    },
    countryOptions() {
      return [].concat(this.countries.map(country => {
        return {
          text: country.country_name.toUpperCase(),
          value: country.id
        }
      }));
    },
    countrySelected() {
      if (this.client.country === null) return null;

      return this.countries.find(c => c.id == this.client.country);
    },
    cityOptions() {
      let options = [{text: 'Elija una ciudad', value: null}];
      if (this.countrySelected !== null) {
        options = options.concat(this.countrySelected.cities.map(city => {
          return {
            text: city.city_name.toUpperCase(),
            value: city.id
          }
        }));
      }

      return options;
    },
    stateOptions() {
      let options = [];
      if (this.countrySelected) {
        options = options.concat(this.countrySelected.states.map(state => {
          return {
            text: state.name.toUpperCase(),
            value: state.id
          }
        }));

        if (options.length > 0) {
          options = [{text: 'Elija un estado', value: null}].concat(options);
        }
      }

      return options;
    },
    membershipType() {
      if (this.client.membership_type === null) return null;

      return this.membershipTypes.find(m => m.id === this.client.membership_type);
    }
  },
  methods: {
    onCaptchaVerified(recaptchaToken){
      this.captchaver = true;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    hasErrors(field) {
      return this.errors.hasOwnProperty(field) && Array.isArray(this.errors[field]) && this.errors[field].length > 0;
    },
    getErrors(field) {
      if (this.hasErrors(field)) {
        return this.errors[field];
      }

      return [];
    },
    async loadCountries() {
      let { data: countries } = await axios.get('/api/countries');

      this.countries = countries;
    },
    checkOld() {
      if (this.old instanceof Object) {
        if (this.old.hasOwnProperty('name')) {
          this.client.name = this.old.name;
        }
        
        if (this.old.hasOwnProperty('last_name')) {
          this.client.last_name = this.old.last_name;
        }

        if (this.old.hasOwnProperty('email')) {
          this.client.email = this.old.email;
        }

        if (this.old.hasOwnProperty('phone_number')) {
          this.client.phone_number = this.old.phone_number;
        }
        
        if (this.old.hasOwnProperty('international_code')) {
          this.client.international_code = this.old.international_code;
        }

        if (this.old.hasOwnProperty('address')) {
          this.client.address = this.old.address;
        }

        if (this.old.hasOwnProperty('zip_code')) {
          this.client.zip_code = this.old.zip_code;
        }

        if (this.old.hasOwnProperty('passport')) {
          this.client.passport = this.old.passport;
        }

        if (this.old.hasOwnProperty('password_confirmation')) {
          this.client.password_confirmation = this.old.password_confirmation;
        }

        if (this.old.hasOwnProperty('membership_type_id')) {
          this.client.membership_type = this.old.membership_type_id;
        }

        if (this.old.hasOwnProperty('country')) {
          this.client.country = this.old.country;
        }

        if (this.old.hasOwnProperty('city_id')) {
          this.client.city = this.old.city_id;
        }

        if (this.old.hasOwnProperty('state_id')) {
          this.client.state = this.old.state_id;
        }
      }
    },
    async validateCoupon() {
      let response = null;
      this.is_coupon_loading = true;
      if (this.client.membership_type == 0) {
        response = await axios.get(`/coupon/${this.coupon}`);
      } else {
        response = await axios.get(`/membership-coupon/${this.coupon}`);
      }
        this.coupon_validated = true;
        this.valid_coupon = response.data.is_valid;
        this.is_coupon_loading = false;
        this.coupon_message = response.data.message || null;
    },
    createPaymentMethodSuccessfully(paymentMethod) {
      this.payment_method_id = paymentMethod.id;
      this.$nextTick(() => {
        this.$refs['subscriptionForm'].submit();
      });
    }
  }
}
</script>